<template>
  <div :class="`index ${$utils.isMobile().mobile ? 'mobile' : 'pc'}`">
    <pop-top @open="popLoginOpen" :black="true" v-if="!this.isBack"></pop-top>
    <pop-login
      :show="popLoginShow"
      :projectcode="project_code"
      @close="popLoginClose"
      @loginsuccess="loginSuccess"
    ></pop-login>
    <van-calendar
      v-model="calendarShow"
      :position="$utils.isMobile().mobile ? 'bottom' : 'bottom'"
      @confirm="calendarConfirm"
      :min-date="minDate"
      :max-date="maxDate"
    />
    <van-popup
      v-model="timeShow"
      :position="$utils.isMobile().mobile ? 'bottom' : 'center'"
      round
    >
      <van-datetime-picker
        :visible-item-count="$utils.isMobile().mobile ? 8 : 16"
        v-model="currentTime"
        type="time"
        title="选择时间"
        @confirm="timeConfirm"
        @cancel="timeCancel"
      />
    </van-popup>
    <div class="title">
      <span>活动数据统计</span>
      <span>/ Activity Data Statistics</span>
    </div>
    <div class="info-item">
      <div class="left">
        <span>活动主题</span>
      </div>
      <div class="right">
        <v-select
          class="select-wrapper"
          :options="selectList"
          v-model="selectValue"
          label="选择活动主题"
        ></v-select>
      </div>
    </div>
    <div class="info-item">
      <div class="left">
        <span>活动时间</span>
      </div>
      <div class="right">
        <div class="time-wrapper cursor-pointer" @click="calendarOpen(0)">
          <span>{{ startDate }}</span>
          <div class="icon-calendar"></div>
        </div>
        <div class="time-wrapper cursor-pointer" @click="timeOpen(0)">
          <span>{{ startTime }}</span>
          <div class="icon-time"></div>
        </div>
        <span class="time-split">{{
          $utils.isMobile().mobile ? "至" : "——"
        }}</span>
        <div class="time-wrapper cursor-pointer" @click="calendarOpen(1)">
          <span>{{ endDate }}</span>
          <div class="icon-calendar"></div>
        </div>
        <div class="time-wrapper cursor-pointer" @click="timeOpen(1)">
          <span>{{ endTime }}</span>
          <div class="icon-time"></div>
        </div>
      </div>
    </div>
    <div class="info-item card-info">
      <div class="card-item" v-for="(i, k) in cardList" :key="k">
        <span class="card-eng">{{ i.eng }}</span>
        <p class="card-title">{{ i.title }}</p>
        <div class="card-bottom">
          <span class="card-value">{{ i.value }}</span>
          <span class="card-unit">{{ i.unit }}</span>
          <img class="card-icon" :src="i.icon" />
        </div>
      </div>
    </div>
    <div class="info-item">
      <div class="left">
        <span>同时在线人数</span>
      </div>
    </div>
    <div class="info-item info-charts-wrapper">
      <div class="info-charts" id="info-echarts"></div>
    </div>
    <div class="info-item header">
      <div class="left">
        <span>参与详情</span>
      </div>
      <div class="right">
        <div
          class="create-action cursor-pointer"
          @click.stop.prevent="doExport"
        >
          <div class="icon-export"></div>
          <span>导出列表</span>
        </div>
      </div>
    </div>
    <div class="info-item column info-list-wrapper">
      <div class="info-list th">
        <div class="info-list-tr">
          <div
            :class="`info-list-td ${
              i == '参与时长' || i == '首次进入时间' ? 'cursor-pointer' : ''
            }`"
            v-for="(i, k) in listHeader"
            @click.stop.prevent="listHeaderHandler(i, k)"
            :key="k"
          >
            <span>{{ i }}</span>
            <img
              v-if="listCurrentSortIndex == k"
              :src="
                listCurrentSortValue
                  ? $ossRes('/statistic/icon_sort_asc.png')
                  : $ossRes('/statistic/icon_sort_desc.png')
              "
            />
          </div>
        </div>
      </div>
      <div class="info-list tbody">
        <div class="info-list-tr" v-for="(i, k) in listBody" :key="k">
          <div class="info-list-td">
            <p>{{ i?.activity?.title }}</p>
          </div>
          <div class="info-list-td">
            <p>{{ i?.client?.nickname }}</p>
          </div>
          <div class="info-list-td">
            <span>{{ i?.client?.phone }}</span>
          </div>
          <!-- <div class="info-list-td">
            <span>{{ i?.client?.user_tag }}</span>
          </div> -->
          <div class="info-list-td">
            <span>{{ i.total_online_time }}</span>
          </div>
          <div class="info-list-td">
            <span>{{ i.min_login_time }}</span>
          </div>
          <div class="info-list-td">
            <span>{{ i.max_logout_time }}</span>
          </div>
          <div class="info-list-td">
            <span>{{ i.system }}</span>
          </div>
        </div>
      </div>
      <div class="info-page">
        <div
          v-if="currentPage > 1"
          class="page-left cursor-pointer"
          @click.stop.prevent="pageChange(false)"
        ></div>
        <span
          v-for="(i, k) in pageList"
          :key="k"
          :class="`cursor-pointer ${i != '...' ? 'number' : ''} ${
            i == currentPage ? 'active' : ''
          }`"
          @click.stop.prevent="pageUpdate(i)"
          >{{ i }}</span
        >
        <div
          v-if="currentPage < pageTotal"
          class="page-right cursor-pointer"
          @click.stop.prevent="pageChange(true)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import echarts from "@/plugins/echarts";
import PopTop from "@/components/PopTop.vue";
import PopLogin from "@/components/PopLogin.vue";
export default {
  components: { PopTop, PopLogin },
  data() {
    const now = Date.now();
    const date = this.$utils.timestampToTime(now).substring(0, 10);
    const time = this.$utils.timestampToTime(now).substring(11, 16);
    const tomorrow = this.$utils
      .timestampToTime(now + 86400000)
      .substring(0, 10);
    return {
      project_code: "",
      isBack: 0,
      id: "",
      minDate: new Date(2022, 0, 1),
      maxDate: new Date(2099, 0, 31),
      startDate: date,
      startTime: time,
      endDate: tomorrow,
      endTime: time,
      calendarShow: false,
      currentCalendarType: false,
      currentTime: "00:00",
      timeShow: false,
      currentTimeType: false,
      selectBegin: false,
      selectValue: "",
      activityList: [],
      selectList: [],
      listHeader: [
        "活动名称",
        "用户名称",
        "用户手机号",
        // "用户标识",
        "参与时长",
        "首次进入时间",
        "末次退出时间",
        "使用终端",
      ],
      listCurrentSortIndex: -1,
      listCurrentSortValue: false,
      listCurrentSortField: "",
      listBody: [],
      cardList: [
        {
          title: "参与时长",
          eng: "Duration",
          icon: this.$ossRes("/statistic/icon_duraction.png"),
          value: 0,
          unit: "分钟",
        },
        {
          title: "参与人数",
          eng: "Number",
          icon: this.$ossRes("/statistic/icon_number.png"),
          value: 0,
          unit: "人",
        },
        {
          title: "人均参与时长",
          eng: "Duration",
          icon: this.$ossRes("/statistic/icon_duraction_per.png"),
          value: 0,
          unit: "分钟",
        },
        {
          title: "最高同时在线人数",
          eng: "Duration",
          icon: this.$ossRes("/statistic/icon_duraction_most.png"),
          value: 0,
          unit: "人",
        },
      ],
      chart: {},
      chartOption: {},
      back: 0,
      admin: 0,
      isBackUserId: "",
      needToken: false,
      currentPage: 1,
      pageSize: 10,
      recordTotal: 1,
      pageTotal: 1,
      pageList: [],
    };
  },
  watch: {
    selectValue: {
      handler: function (v) {
        if (v && this.selectBegin) {
          this.activityList.map((i) => {
            if (`${i.id} ${i.title}` == this.selectValue) {
              this.id = i.id;
              this.startDate = i.start_time.substring(0, 10);
              this.startTime = i.start_time.substring(11, 16);
              this.endDate = i.end_time.substring(0, 10);
              this.endTime = i.end_time.substring(11, 16);
            }
          });
          if (this.selectValue == "全部") {
            this.id = "";
          }
          this.resetData();
        }
      },
    },
  },
  created() {
    this.isBack = !!(this.$route.query.back || 0);
    this.isAdmin = !!(this.$route.query.admin || 0);
    this.isBackUserId = this.$route.query.user_id || "";
    this.id = this.$route.query.id;
  },
  mounted() {
    this.getInfo();
    this.getTotal();
    this.getActivityList();
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  methods: {
    listHeaderHandler(i, k) {
      if (i == "参与时长") {
        if (k == this.listCurrentSortIndex) {
          this.listCurrentSortValue = !this.listCurrentSortValue;
        } else {
          this.listCurrentSortIndex = k;
          this.listCurrentSortValue = false;
          this.listCurrentSortField = "online_time";
        }
        this.getInfo();
      } else if (i == "首次进入时间") {
        if (k == this.listCurrentSortIndex) {
          this.listCurrentSortValue = !this.listCurrentSortValue;
        } else {
          this.listCurrentSortIndex = k;
          this.listCurrentSortValue = false;
          this.listCurrentSortField = "min_login_time";
        }
        this.getInfo();
      }
    },
    popLoginClose() {
      this.popLoginShow = false;
    },
    async doExport() {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "正在加载中...",
      });
      const requestParam = {
        page: this.currentPage,
        per_page: this.pageSize,
        start_time: `${this.startDate} ${this.startTime}`,
        end_time: `${this.endDate} ${this.endTime}`,
        id: this.id,
        export: 1,
      };
      if (this.userInfo && !this.isBack) {
        requestParam.user_id = this.userInfo.user_id;
      }
      if (this.isBackUserId) {
        requestParam.user_id = this.isBackUserId;
      }
      const url =
        process.env.VUE_APP_PROXY_URL +
        this.$http.get("/count/join-list", requestParam, this.needToken, true);
      this.$toast.clear();
      window.open(url);
    },
    resetData() {
      this.initEcharts();
      this.getTotal();
      this.getInfo();
    },
    async getActivityList() {
      const requestParam = {};
      if (this.userInfo && !this.isBack) {
        requestParam.user_id = this.userInfo.user_id;
      }
      if (this.isBackUserId) {
        requestParam.user_id = this.isBackUserId;
      }
      const { code, data, msg } = await this.$http.get(
        "/count/activity-list",
        requestParam,
        this.needToken
      );
      if (code == 200) {
        this.activityList = data;
        const list = [];
        this.isBack &&
          this.isAdmin &&
          list.push("全部") &&
          (this.selectValue = "全部");
        data.map((i) => {
          list.push(`${i.id} ${i.title}`);
          if (i.id == this.id) {
            this.selectValue = `${i.id} ${i.title}`;
            this.startDate = i.start_time.substring(0, 10);
            this.startTime = i.start_time.substring(11, 16);
            this.endDate = i.end_time.substring(0, 10);
            this.endTime = i.end_time.substring(11, 16);
          }
        });
        this.selectList = list;
        this.selectBegin = true;
        this.initEcharts();
      } else {
        this.$toast.fail("活动列表加载失败：" + msg);
      }
      this.weappPathFinished = true;
    },
    pageChange(right) {
      let value = this.currentPage;
      if (right) {
        if (this.currentPage >= this.pageTotal) {
          return;
        }
        value = this.currentPage + 1;
      } else {
        if (this.currentPage <= 1) {
          return;
        }
        value = this.currentPage - 1;
      }
      this.pageUpdate(value);
    },
    pageUpdate(v) {
      if (v == "...") {
        return;
      }
      this.currentPage = v;
      this.getInfo();
    },
    pageInit() {
      this.pageTotal = Math.ceil(this.recordTotal / this.pageSize);
      let step = 1,
        count = 7,
        list = [];
      if (this.pageTotal <= count + 1) {
        while (step <= this.pageTotal) list.push(step++);
      } else {
        if (this.currentPage <= count - 2) {
          while (step <= count) list.push(step++);
          list.push("...");
          list.push(this.pageTotal);
        } else if (this.currentPage >= this.pageTotal - 4) {
          while (step <= 2) list.push(step++);
          list.push("...");
          step = this.pageTotal - 6;
          while (step <= this.pageTotal) list.push(step++);
        } else {
          while (step <= 2) list.push(step++);
          list.push("...");
          step = this.currentPage - 2;
          while (step <= this.currentPage + 2 && step < this.pageTotal - 2)
            list.push(step++);
          list.push("...");
          step = this.pageTotal - 1;
          while (step <= this.pageTotal) list.push(step++);
        }
      }
      this.pageList = list;
    },
    async getInfo() {
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "正在加载中...",
      });
      const requestParam = {
        page: this.currentPage,
        per_page: this.pageSize,
        start_time: `${this.startDate} ${this.startTime}`,
        end_time: `${this.endDate} ${this.endTime}`,
        id: this.id,
      };
      if (this.userInfo && !this.isBack) {
        requestParam.user_id = this.userInfo.user_id;
      }
      if (this.isBackUserId) {
        requestParam.user_id = this.isBackUserId;
      }
      if (this.listCurrentSortIndex > -1) {
        requestParam.sort_field = this.listCurrentSortField;
        requestParam.sort_type = this.listCurrentSortValue ? "asc" : "desc";
      }
      const { code, data } = await this.$http.get(
        "/count/join-list",
        requestParam,
        this.needToken
      );
      this.$toast.clear();
      if (code == 200) {
        this.listBody = data.data;
        this.recordTotal = data.total;
        this.pageInit();
      }
    },
    async getTotal() {
      const requestParam = {
        start_time: `${this.startDate} ${this.startTime}`,
        end_time: `${this.endDate} ${this.endTime}`,
        id: this.id,
      };
      if (this.userInfo && !this.isBack) {
        requestParam.user_id = this.userInfo.user_id;
      }
      if (this.isBackUserId) {
        requestParam.user_id = this.isBackUserId;
      }
      const { code, data } = await this.$http.get(
        "/count/activity-statistic",
        requestParam,
        this.needToken
      );
      if (code == 200) {
        this.cardList[0].value = data.total_online_time;
        this.cardList[1].value = data.total_people;
        this.cardList[2].value = data.avg_online_time;
      }
    },
    async initEcharts() {
      const _this = this;
      this.$toast.loading({
        message: "正在加载中...",
        duration: 0,
        forbidClick: true,
      });
      if (this.chart != null && this.chart && this.chart.dispose) {
        this.chart.dispose();
      }
      this.chart = echarts.init(document.getElementById("info-echarts"));
      const requestParam = {
        start_time: `${this.startDate} ${this.startTime}`,
        end_time: `${this.endDate} ${this.endTime}`,
        id: this.id,
      };
      if (this.userInfo && !this.isBack) {
        requestParam.user_id = this.userInfo.user_id;
      }
      if (this.isBackUserId) {
        requestParam.user_id = this.isBackUserId;
      }
      const { code, data, msg } = await this.$http.post(
        "/count/join-chart",
        requestParam,
        this.needToken
      );
      if (code != 200) {
        this.$toast.fail(`初始化列表失败：${msg}`);
        return;
      }
      let maxValue = 0;
      data.map((i) => {
        if (Number(i.total) > maxValue) {
          maxValue = Number(i.total);
        }
      });
      this.cardList[3].value = maxValue;
      this.chart.hideLoading();
      let datasetResource = [
        ["date", "日期", "同时在线人数"],
        ...data.map((i) => {
          return [
            _this.$utils.timeItemFormat(i.month) +
              "-" +
              _this.$utils.timeItemFormat(i.day) +
              " " +
              i.time,
            i.year +
              "-" +
              _this.$utils.timeItemFormat(i.month) +
              "-" +
              _this.$utils.timeItemFormat(i.day),
            i.total,
          ];
        }),
      ];
      if (this.selectValue == "全部") {
        datasetResource = [
          ["date", "日期", "同时在线人数"],
          ...data.map((i) => {
            return [
              "活动" +
                " " +
                i.activity_id +
                " " +
                i.activity_name +
                " " +
                _this.$utils.timeItemFormat(i.month) +
                "-" +
                _this.$utils.timeItemFormat(i.day) +
                " " +
                i.time,
              i.year +
                "-" +
                _this.$utils.timeItemFormat(i.month) +
                "-" +
                _this.$utils.timeItemFormat(i.day),
              i.total,
            ];
          }),
        ];
      }
      let option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
            },
          },
        },
        dataset: {
          source: datasetResource,
        },
        calculable: true,
        grid: {
          top: "12%",
          left: "1%",
          right: "10%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          // data: data.map((i) => i.time),
        },

        yAxis: [
          {
            type: "value",
            name: "人数",
          },
        ],
        dataZoom: [
          {
            show: true,
            start: 0,
            end: 100,
          },
          {
            type: "inside",
            start: 0,
            end: 100,
          },
        ],
        series: [
          {
            type: "line",
            itemStyle: {
              color: echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(143, 139, 245, 0.60)" },
                { offset: 1, color: "rgba(216, 216, 216, 0.10)" },
              ]),
            },
          },
          {
            type: "bar",
            itemStyle: {
              color: echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(143, 139, 245, 0.60)" },
                { offset: 1, color: "rgba(216, 216, 216, 0.10)" },
              ]),
            },
            large: true,
          },
        ],
      };
      this.chart.setOption(option);
      this.$toast.clear();
    },
    timeOpen(type) {
      this.currentTimeType = type;
      if (!type) {
        this.currentTime = this.startTime;
      } else {
        this.currentTime = this.endTime;
      }
      this.timeShow = true;
    },
    timeConfirm() {
      if (!this.currentTimeType) {
        this.startTime = this.currentTime;
      } else {
        this.endTime = this.currentTime;
      }
      this.timeShow = false;
      this.resetData();
    },
    timeCancel() {
      this.timeShow = false;
    },
    calendarOpen(type) {
      this.currentCalendarType = type;
      this.calendarShow = true;
    },
    calendarConfirm(date) {
      if (!this.currentCalendarType) {
        this.startDate = this.$utils
          .timestampToTime(date.getTime())
          .substring(0, 11);
      } else {
        this.endDate = this.$utils
          .timestampToTime(date.getTime())
          .substring(0, 11);
      }
      this.calendarShow = false;
      this.resetData();
    },
    popLoginOpen() {
      this.popLoginShow = true;
    },
  },
};
</script>
<style lang="scss">
@media (orientation: landscape) {
  .pc {
    .van-calendar__popup {
      width: 1000px !important;
      height: 1450px !important;
      left: 50% !important;
      bottom: 50% !important;
      margin-left: -500px !important;
      margin-bottom: -725px !important;
      border-radius: 60px !important;
    }
  }
}
</style>
<style lang="scss">
:root {
  --vs-search-input-bg: #fafafc;
}
.vs__dropdown-toggle {
  height: 100%;
}
.vs__selected-options {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>
<style lang="scss" scoped>
.index {
  width: 100%;
  min-height: 2160px;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100% !important;
  text-size-adjust: 100% !important;
  -moz-text-size-adjust: 100% !important;
  background: url(#{$PublicEnv}/activity_my/my_activity_bg_pc.png);
  background-size: 100% 100%;
  position: relative;
  .title {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 244px;
    box-sizing: border-box;
    padding-left: 280px;
    span {
      &:first-child {
        font-size: 56px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #252727;
        margin-right: 20px;
      }
      &:nth-child(2) {
        font-size: 40px;
        font-family: DIN-Bold, DIN;
        font-weight: bold;
        text-shadow: 0px 16px 64px rgba(0, 0, 0, 0.16);
        background: linear-gradient(
          270deg,
          #4e54ff 13%,
          #21b4c5 51%,
          #1285d1 91%
        );
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .info-item {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 60px;
    box-sizing: border-box;
    padding: 0 280px;
    &.column {
      flex-direction: column;
    }
    &:last-child {
      margin-bottom: 0;
      padding-bottom: 200px;
    }
    .info-charts {
      flex: 1;
      height: 1060px;
    }
    .left {
      flex-shrink: 0;
      margin-right: 62px;
      padding-top: 12px;
      span {
        font-size: 40px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 500;
        color: #333333;
      }
    }
    .right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      .item-input {
        width: 1462px;
        height: 84px;
        background: transparent;
        border: 1px solid #a8a8a8;
        border-radius: 8px 8px 8px 8px;
        padding: 0 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 36px;
        font-family: DIN-Medium, DIN;
        font-weight: 500;
        color: #333333;
        &::placeholder {
          font-size: 36px;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 400;
          color: #bbbbbb;
        }
      }
      .item-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 44px;
        .item-cover {
          margin-right: 0;
        }
        .item-label {
          font-size: 36px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          margin-top: 28px;
        }
      }
      .select-wrapper {
        width: 690px;
        height: 84px;
        box-sizing: border-box;
        background: #fafafc;
        border-radius: 10px 10px 10px 10px;
        border: 1px solid rgba(112, 112, 112, 0.2392);
        select {
          appearance: none;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          box-sizing: border-box;
          padding: 0 40px;
          font-size: 36px;
          font-family: DIN-Medium, DIN;
          font-weight: 500;
          color: #333333;
          background: inherit;
          border: 0;
          option {
            width: calc(100% + 40px);
          }
        }
        &.fake {
          position: absolute;
          left: 580px;
          top: 50%;
          margin-top: -20px;
          height: 40px;
          width: 40px;
          border: 0;
          padding-right: 40px;
          pointer-events: none;
          select {
            appearance: auto;
          }
        }
      }
      .time-wrapper {
        margin-right: 20px;
        width: 360px;
        height: 84px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #fafafc;
        border-radius: 10px 10px 10px 10px;
        border: 1px solid rgba(112, 112, 112, 0.2392);
        box-sizing: border-box;
        padding: 0 20px 0 40px;
        &:last-child {
          margin-right: 0;
        }
        span {
          font-size: 36px;
          font-family: DIN-Medium, DIN;
          font-weight: 500;
          color: #333333;
        }
        .icon-calendar {
          width: 56px;
          height: 56px;
          background: url(#{$PublicEnv}/activity_my/icon_calendar.png);
          background-size: 100% 100%;
        }
        .icon-time {
          width: 56px;
          height: 56px;
          background: url(#{$PublicEnv}/activity_my/icon_time.png);
          background-size: 100% 100%;
        }
      }
      .time-split {
        margin-left: 8px;
        margin-right: 28px;
        font-size: 36px;
        font-family: Source Han Sans CN-Normal, Source Han Sans CN;
        font-weight: 400;
        color: #bbbbbb;
      }
      .icon-minus {
        width: 44px;
        height: 44px;
        background: url(#{$PublicEnv}/activity_my/icon_minus.png);
        background-size: 100% 100%;
      }
      .count-number {
        width: 106px;
        height: 84px;
        background: #fafafc;
        border-radius: 10px 10px 10px 10px;
        border: 1px solid rgba(112, 112, 112, 0.2392);
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 36px;
        font-family: DIN-Medium, DIN;
        font-weight: 500;
        color: #999999;
        // margin: 0 24px;
      }
      .icon-add {
        width: 44px;
        height: 44px;
        background: url(#{$PublicEnv}/activity_my/icon_add.png);
        background-size: 100% 100%;
      }
    }

    &.header {
      justify-content: space-between;
      .right {
        flex: 0;
        .create-action {
          width: 310px;
          height: 119px;
          background: url(#{$PublicEnv}/activity_my/activity_create_bg.png);
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          padding-top: 14px;
          box-sizing: border-box;
          .icon-export {
            width: 40px;
            height: 40px;
            background: url(#{$PublicEnv}/statistic/icon_upload.png);
            background-size: 100% 100%;
            margin-top: 5px;
            margin-right: 12px;
          }
          span {
            font-size: 36px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
          }
        }
      }
    }
    .card-item {
      width: 460px;
      height: 258px;
      position: relative;
      margin-right: 40px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.1);
      border-radius: 18px;
      &:last-child {
        margin-right: 0;
      }
      .card-eng {
        z-index: 1;
        position: absolute;
        top: 8px;
        right: 28px;
        font-size: 56px;
        font-family: Source Han Sans CN-Heavy, Source Han Sans CN;
        font-weight: 900;
        color: #f6f7f9;
        opacity: 0.8;
      }
      .card-title {
        z-index: 2;
        position: absolute;
        left: 40px;
        top: 26px;
        font-size: 32px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #666666;
      }
      .card-bottom {
        position: absolute;
        bottom: 40px;
        left: 40px;
        right: 40px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .card-value {
          flex-shrink: 0;
          font-size: 52px;
          font-family: DIN-Bold, DIN;
          font-weight: 700;
          color: #333333;
        }
        .card-unit {
          margin-left: 20px;
          padding-bottom: 10px;
          flex: 1;
          text-align: left;
          font-size: 24px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #999999;
        }
        .card-icon {
          flex-shrink: 0;
          width: 98px;
          height: 98px;
          position: absolute;
          right: -20px;
          bottom: -10px;
        }
      }
    }
    .info-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding: 0 80px;
      .info-list-tr {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .info-list-td {
          height: 80px;
          flex: 1;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          span {
            font-size: 32px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #333333;
          }
          p {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 32px;
            font-family: Source Han Sans CN-Medium, Source Han Sans CN;
            font-weight: 500;
            color: #333333;
          }
          img {
            width: 36px;
            height: 36px;
            margin-left: 12px;
            margin-top: 6px;
          }
        }
      }
      &.th {
        padding: 0 0 0 80px;
        .info-list-tr {
          .info-list-td {
            span {
              font-size: 32px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #666666;
            }
          }
        }
      }
      &.tbody {
        padding: 40px 0 40px 80px;
        background: rgba(245, 246, 249, 1);
        margin-top: 36px;
      }
    }
    .info-page {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 160px;
      .page-left {
        width: 72px;
        height: 72px;
        background: url(#{$PublicEnv}/statistic/icon_left.png);
        background-size: 100% 100%;
        margin-right: 36px;
      }
      .page-right {
        width: 72px;
        height: 72px;
        background: url(#{$PublicEnv}/statistic/icon_right.png);
        background-size: 100% 100%;
      }
      span {
        font-size: 44px;
        font-family: DIN-Bold-Regular, DIN-Bold;
        font-weight: 400;
        color: #666666;
        margin-right: 36px;
        &.number {
          width: 73px;
          height: 73px;
          border-radius: 50%;
          background: #e7e9ea;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 36px;
          font-family: DIN-Regular, DIN;
          font-weight: 500;
          color: #666666;
          &.active {
            background: #496eec;
            color: #ffffff;
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
@media (orientation: portrait) {
  .index {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    -webkit-text-size-adjust: 100% !important;
    text-size-adjust: 100% !important;
    -moz-text-size-adjust: 100% !important;
    background: url(#{$PublicEnv}/activity_my/my_activity_bg_pc.png);
    background-size: 100% 100%;
    position: relative;
    .title {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 778px;
      box-sizing: border-box;
      padding-left: 205px;
      span {
        &:first-child {
          font-size: 215px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #252727;
          margin-right: 20px;
        }
        &:nth-child(2) {
          margin-top: 40px;
          font-size: 195px;
          font-family: DIN-Bold, DIN;
          font-weight: bold;
          text-shadow: 0px 41px 164px rgba(0, 0, 0, 0.16);
          background: linear-gradient(
            270deg,
            #4e55ff 13%,
            #21b4c5 51%,
            #1285d1 91%
          );
          background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
    }

    .info-item {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: flex-start;
      margin-top: 200px;
      box-sizing: border-box;
      padding: 0 205px;
      &.column {
        flex-direction: column;
      }
      &:last-child {
        margin-bottom: 0;
        padding-bottom: 200px;
      }
      &.card-info {
        flex-direction: row;
        flex-wrap: wrap;
      }
      &.info-charts-wrapper {
        flex-direction: row;
      }
      .info-charts {
        flex: 1;
        height: 3180px;
      }
      .left {
        flex-shrink: 0;
        margin-right: 62px;
        padding-top: 12px;
        padding-left: 123px;
        position: relative;
        &::before {
          width: 31px;
          height: 164px;
          background: #7076ff;
          border-radius: 51px;
          content: "";
          position: absolute;
          left: 0;
          top: 40px;
        }
        span {
          font-size: 164px;
          font-family: Source Han Sans CN-Medium, Source Han Sans CN;
          font-weight: 500;
          color: #3a3a3a;
        }
      }
      .right {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        position: relative;
        flex-wrap: wrap;
        margin-top: 154px;
        .item-input {
          width: 1462px;
          height: 84px;
          background: transparent;
          border: 1px solid #a8a8a8;
          border-radius: 8px 8px 8px 8px;
          padding: 0 40px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: 36px;
          font-family: DIN-Medium, DIN;
          font-weight: 500;
          color: #333333;
          &::placeholder {
            font-size: 36px;
            font-family: Source Han Sans CN-Normal, Source Han Sans CN;
            font-weight: 400;
            color: #bbbbbb;
          }
        }
        .item-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-right: 44px;
          .item-cover {
            margin-right: 0;
          }
          .item-label {
            font-size: 36px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            margin-top: 28px;
          }
        }
        .select-wrapper {
          width: 100%;
          height: 307px;
          box-sizing: border-box;
          background: #fafafc;
          border-radius: 10px 10px 10px 10px;
          border: 1px solid rgba(112, 112, 112, 0.2392);
          select {
            appearance: none;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            box-sizing: border-box;
            padding: 0 40px;
            font-size: 36px;
            font-family: DIN-Medium, DIN;
            font-weight: 500;
            color: #333333;
            background: inherit;
            border: 0;
            option {
              width: calc(100% + 40px);
            }
          }
          &.fake {
            position: absolute;
            left: 580px;
            top: 50%;
            margin-top: -20px;
            height: 40px;
            width: 40px;
            border: 0;
            padding-right: 40px;
            pointer-events: none;
            select {
              appearance: auto;
            }
          }
        }
        .time-wrapper {
          margin-right: 20px;
          width: 1400px;
          height: 307px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #fafafc;
          border-radius: 10px 10px 10px 10px;
          border: 1px solid rgba(112, 112, 112, 0.2392);
          box-sizing: border-box;
          padding: 0 20px 0 40px;
          margin-bottom: 40px;
          margin-right: 40px;
          &:last-child {
            margin-right: 0;
          }
          span {
            font-size: 164px;
            font-family: DIN-Medium, DIN;
            font-weight: 500;
            color: #333333;
          }
          .icon-calendar {
            width: 205px;
            height: 205px;
            background: url(#{$PublicEnv}/activity_my/icon_calendar.png);
            background-size: 100% 100%;
          }
          .icon-time {
            width: 205px;
            height: 205px;
            background: url(#{$PublicEnv}/activity_my/icon_time.png);
            background-size: 100% 100%;
          }
        }
        .time-split {
          margin-left: 8px;
          margin-right: 28px;
          font-size: 164px;
          font-family: Source Han Sans CN-Normal, Source Han Sans CN;
          font-weight: 400;
          color: #bbbbbb;
        }
        .icon-minus {
          width: 44px;
          height: 44px;
          background: url(#{$PublicEnv}/activity_my/icon_minus.png);
          background-size: 100% 100%;
        }
        .count-number {
          width: 106px;
          height: 84px;
          background: #fafafc;
          border-radius: 10px 10px 10px 10px;
          border: 1px solid rgba(112, 112, 112, 0.2392);
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 36px;
          font-family: DIN-Medium, DIN;
          font-weight: 500;
          color: #999999;
          // margin: 0 24px;
        }
        .icon-add {
          width: 44px;
          height: 44px;
          background: url(#{$PublicEnv}/activity_my/icon_add.png);
          background-size: 100% 100%;
        }
      }

      &.header {
        justify-content: space-between;
        flex-direction: row;
        align-items: flex-start;
        .right {
          flex: 0;
          margin-top: 0;
          .create-action {
            width: 947px;
            height: 363.5px;
            background: url(#{$PublicEnv}/activity_my/activity_create_bg.png);
            background-size: 100% 100%;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            padding-top: 30px;
            box-sizing: border-box;
            .icon-export {
              width: 164px;
              height: 164px;
              background: url(#{$PublicEnv}/statistic/icon_upload.png);
              background-size: 100% 100%;
              margin-top: 5px;
              margin-right: 20px;
              flex-shrink: 0;
            }
            span {
              font-size: 133px;
              font-family: Source Han Sans CN-Medium, Source Han Sans CN;
              font-weight: 500;
              color: #ffffff;
            }
          }
        }
      }
      .card-item {
        width: 1649px;
        height: 768px;
        position: relative;
        margin-right: 100px;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0 8px 40px 0 rgba(0, 0, 0, 0.1);
        border-radius: 18px;
        margin-bottom: 100px;
        &:nth-child(2n) {
          margin-right: 0;
        }
        .card-eng {
          z-index: 1;
          position: absolute;
          top: 82px;
          right: 113px;
          font-size: 174px;
          font-family: Source Han Sans CN-Heavy, Source Han Sans CN;
          font-weight: 900;
          color: #f6f7f9;
          opacity: 0.8;
        }
        .card-title {
          z-index: 2;
          position: absolute;
          left: 102px;
          top: 102px;
          font-size: 143px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #666666;
        }
        .card-bottom {
          position: absolute;
          bottom: 100px;
          left: 102px;
          right: 102px;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .card-value {
            flex-shrink: 0;
            font-size: 195px;
            font-family: DIN-Bold, DIN;
            font-weight: 700;
            color: #333333;
          }
          .card-unit {
            margin-left: 20px;
            padding-bottom: 30px;
            flex: 1;
            text-align: left;
            font-size: 102px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #999999;
          }
          .card-icon {
            flex-shrink: 0;
            width: 400px;
            height: 400px;
            position: absolute;
            right: -80px;
            bottom: -70px;
          }
        }
      }
      &.info-list-wrapper {
        display: block;
        overflow-x: auto;
        position: relative;
        margin-top: 0;
      }
      .info-list {
        width: 300%;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 0 80px;
        .info-list-tr {
          width: 100%;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          .info-list-td {
            height: 461px;
            flex: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            span {
              font-size: 123px;
              font-family: Source Han Sans CN-Medium, Source Han Sans CN;
              font-weight: 500;
              color: #333333;
            }
            p {
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 123px;
              font-family: Source Han Sans CN-Medium, Source Han Sans CN;
              font-weight: 500;
              color: #333333;
            }
          }
        }
        &.th {
          padding: 0 0 0 80px;
          .info-list-tr {
            .info-list-td {
              span {
                font-size: 123px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                color: #666666;
              }
            }
          }
        }
        &.tbody {
          padding: 40px 0 40px 80px;
          background: rgba(245, 246, 249, 1);
          margin-top: 36px;
        }
      }
      .info-page {
        width: fit-content;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 160px;
        .page-left {
          width: 250px;
          height: 250px;
          background: url(#{$PublicEnv}/statistic/icon_left.png);
          background-size: 100% 100%;
          margin-right: 123px;
        }
        .page-right {
          width: 250px;
          height: 250px;
          background: url(#{$PublicEnv}/statistic/icon_right.png);
          background-size: 100% 100%;
        }
        span {
          font-size: 143px;
          font-family: DIN-Bold-Regular, DIN-Bold;
          font-weight: 400;
          color: #666666;
          margin-right: 123px;
          &.number {
            width: 250px;
            height: 250px;
            border-radius: 50%;
            background: #e7e9ea;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 143px;
            font-family: DIN-Regular, DIN;
            font-weight: 500;
            color: #666666;
            &.active {
              background: #496eec;
              color: #ffffff;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>
